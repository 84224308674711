<template>
  <div class="export-stocks">
    <KTCodePreview v-bind:title="'Danh sách phiếu xuất kho'">
      <template v-slot:toolbar>
        <div class="row">
          <div>
            <b-dropdown
              size="sm"
              id="dropdown-1"
              right
            >
              <template slot="button-content">
                <i
                  style="font-size: 1rem"
                  class="fas fa-cog"
                ></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="reportClick">
                <span>
                  <i
                    style="font-size: 1rem"
                    class="far fa-file-excel"
                  ></i>
                  &nbsp; Xuất Excel</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div
            v-if="checkPermission('STOCK_EXPORT_INSERT')"
            class="ml-3"
          >
            <router-link to="/export-stocks/add-export-stock">
              <button
                v-bind:style="btnCreate"
                type="button"
                class="btn btn-primary font-weight-bolder btn-sm"
              >
                <i
                  style="font-size: 1rem"
                  class="flaticon2-add-1"
                ></i>Thêm mới
              </button>
            </router-link>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-5">
          <b-col>
            <div class="d-flex justify-content-center align-items-center">
              <date-picker
                placeholder="Từ ngày"
                class="form-control-sm"
                :config="dpConfigs.start"
                v-model="dpForm.startDate"
              ></date-picker>
              <span class="ml-1 mr-1"></span>
              <date-picker
                placeholder="Đến ngày"
                class="form-control-sm"
                :config="dpConfigs.end"
                v-model="dpForm.endDate"
              ></date-picker>
            </div>
          </b-col>
          <b-col>
            <BDropdownCustom
              v-model="valueStore"
              :options="filterdOptionsStore"
              :placeholder="'cửa hàng'"
              :matchKey="['name']"
              :searchable="true"
              :limit="5"
            />
          </b-col>
          <b-col>
            <Autosuggest
              :model="searchProduct"
              :suggestions="filteredOptionsProduct"
              placeholder="mã, tên, mã vạch sản phẩm"
              :limit="10"
              @select="onSelectedProduct"
              @change="onInputChangeProduct"
              suggestionName="productName"
            />
          </b-col>
          <b-col>
            <Autosuggest
              :model="searchProvider"
              :suggestions="filteredOptionProvider"
              placeholder="NCC"
              :limit="10"
              @select="onSelectedProvider"
              @change="onInputChangeProvider"
            />
          </b-col>
          <b-col>
            <b-form-select
              class="select-style"
              size="sm"
              v-model="selectedCompany"
              :options="listCompany"
              value-field="id"
              text-field="name"
              v-on:change="onChangeCompany"
            >
              <template v-slot:first>
                <b-form-select-option
                  :value="null"
                  disabled
                >-- Doanh nghiệp --</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-form-input
              v-model="searchId"
              type="text"
              placeholder="Mã phiếu"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col>
            <b-form-input
              v-model="search"
              type="text"
              placeholder="Tìm kiếm theo nội dung"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col>
            <b-form-select
              class="select-style"
              size="sm"
              v-model="selectedType"
              :options="listType"
              value-field="id"
              text-field="name"
            >
              <template v-slot:first>
                <b-form-select-option
                  :value="null"
                  disabled
                >-- Kiểu xuất kho --</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col></b-col>
          <b-col></b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
            >Lọc</b-button>
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="stocks"
          :fields="fields"
          sort-by="count"
          class="table-bordered table-hover col-md-12"
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table
              :speed="5"
              :animate="true"
              :columns="10"
            ></vcl-table>
          </template>

          <template v-slot:cell(count)="row">
            <div style="text-align: center">
              <span
                v-text="row.item.count"
                class="text-right"
              ></span>
            </div>
          </template>

          <template v-slot:cell(code)="row">
            <div
              class="productCode"
              style="cursor: pointer"
            >
              <a
                class="font-weight-bold"
                style="color: black"
                v-bind:href="getLinkDoc(row.item)"
                target="_blank"
              >
                {{ row.item.code }}
              </a>
            </div>
            <div v-if="row.item.idNhanh" class="text-primary">
              <span
                v-if="
                  row.item.type !==
                  STOCK_SLIP_TYPE.EXPORT_STOCK_BY_WARRANTY_BILL
                "
                >ID Nhanh:</span
              >
              <span style="font-size: 11px">( {{ row.item.idNhanh }} )</span>
            </div>
            <p class="text-primary">{{ row.item.createdAt }}</p>
          </template>
          <template v-slot:cell(relateStockSlipId)="row">
            <div
              class="productCode"
              @click="linkToBill(row.item)"
              style="cursor: pointer"
            >
              <span v-text="row.item.relateStockSlipId"></span>
            </div>
          </template>

          <template v-slot:cell(providerName)="row">
            <div style="text-align: left">
              <span
                v-text="row.item.providerName"
                class="text-right text-primary mb-0"
              ></span>
            </div>
          </template>
          <template v-slot:cell(sourceWarehouseId)="row">
            <span
              v-text="row.item.sourceWarehouseId"
              class="font-weight-bolder"
            ></span>
            <br />
            <span v-if="row.item.companyName">( {{ row.item.companyName }} )</span>
          </template>
          <template v-slot:cell(description)="row">
            <div
              @mouseover="hoverPrice = true"
              @mouseleave="hoverPrice = false"
            >
              <span
                v-text="row.item.description"
                class="mr-2"
              ></span>
              <span @click="handleShowNoteModel(row.item)">
                <i
                  v-if="hoverPrice"
                  class="fas fa-edit"
                ></i>
              </span>
            </div>
          </template>
          <template v-slot:cell(countProduct)="row">
            <div style="text-align: right">
              <span
                v-text="convertPrice(row.item.countProduct)"
                class="text-right"
              ></span>
            </div>
          </template>
          <template v-slot:cell(countQuantity)="row">
            <div style="text-align: right">
              <span
                v-text="convertPrice(row.item.countQuantity)"
                class="text-right"
              ></span>
            </div>
          </template>

          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown
                size="sm"
                id="dropdown-left"
                no-caret
                right
              >
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item @click="printItem(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-printer"
                    ></i>
                    &nbsp; In phiếu
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="editItem(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-pen"
                    ></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item)"
                  v-if="checkPermission('STOCK_EXPORT_DELETE')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p
              class="mt-3 text-dark"
              style="font-weight: 500"
            >
              Tổng :
              {{ convertPrice(totalItems) }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="custom-pagination"
              v-show="totalPages >= 2"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchStock"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm m-1"
              prev-class="page-item-prev btn btn-icon btn-sm m-1"
              next-class="page-item-next btn btn-icon btn-sm m-1 "
              last-class="page-item-last btn btn-icon btn-sm m-1 "
              page-class="btn btn-icon btn-sm border-0 m-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
        <template>
          <b-modal
            v-model="showEditPriceModal"
            hide-footer
            :title="'Sửa ghi chú cho phiếu: ' + stockSlipCode"
          >
            <b-row class="mb-5">
              <b-col>
                <label
                  class="labelInput"
                  for="inputOriginalPrice"
                >Ghi chú</label>
                <b-form-textarea
                  v-model="note"
                  placeholder="Nhập ghi chú..."
                  rows="5"
                  max-rows="6"
                ></b-form-textarea>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="updateNote"
                >Lưu</b-button>
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="handleShowNoteModel"
                >Hủy</b-button>
              </b-col>
            </b-row>
          </b-modal>
        </template>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import { EXPORT_STOCK } from '@/utils/constants';
import fileDownload from '@/utils/file-download';
import { vi } from 'vuejs-datepicker/dist/locale';
import { VclTable } from 'vue-content-loading';
import Swal from 'sweetalert2';
import decounce from 'debounce';
import localData from '@/utils/saveDataToLocal';
import axios from 'axios';
import { BASE_URL } from '@/utils/constants';
import { xoa_dau, removeAccents, convertPrice } from '@/utils/common';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { cloneDeep, map, find, assign } from 'lodash';
import { getToastInstance } from '@/utils/toastHelper';
import { STOCK_SLIP_TYPE } from '@/utils/enum';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';

export default {
  data() {
    return {
      STOCK_SLIP_TYPE,
      dpForm: {
        startDate: moment().subtract(15, 'days').format('DD/MM/YYYY'),
        endDate: '',
      },
      dpConfigs: {
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      onLoading: false,
      disabledDates: {},
      format: 'dd-MM-yyyy',
      language: vi,
      btnCreate: {
        fontWeight: '600!important',
      },
      stocks: [],
      search: '',
      searchStock: '',
      searchId: '',
      currentPage: 0,
      totalPages: 1,
      count: 1,
      totalItems: 0,
      page: 1,
      status: '',
      store: '',
      searchFromDay: '',
      searchToDay: '',
      type: EXPORT_STOCK,
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '4%',
          },
          tdClass: 'sttClass',
        },
        {
          key: 'code',
          label: 'Mã phiếu xuất kho',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '16%' },
        },
        {
          key: 'relateStockSlipId',
          label: 'Từ hóa đơn',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'sourceWarehouseId',
          label: 'Kho',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'providerName',
          label: 'Nhà cung cấp',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'countProduct',
          label: 'SL SP',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
        },
        {
          key: 'countQuantity',
          label: 'Tổng SL',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
        },
        {
          key: 'description',
          label: 'Nội dung',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'createBy',
          label: 'Người tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        { key: 'actions', label: '', thStyle: { width: '5%' } },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      selected: '',
      options: [
        {
          data: [],
        },
      ],
      filteredOptions: [],
      limit: 10,
      searchProduct: '',
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      optionsStore: [
        {
          data: [],
        },
      ],
      selectedCompany: null,
      listCompany: [],
      valueStore: [],
      selectedType: null,
      listType: [
        {
          id: 0,
          name: 'Tất cả',
        },
        {
          id: 2,
          name: 'Xuất bán lẻ',
        },
        {
          id: 3,
          name: 'Xuất bán buôn',
        },
        {
          id: 4,
          name: 'Xuất giao hàng',
        },
        {
          id: 5,
          name: 'Xuất bảo hành',
        },
        {
          id: 1,
          name: 'Xuất trả hàng NCC',
        },
        {
          id: 6,
          name: 'Xuất bảo hành NCC',
        },
        {
          id: 7,
          name: 'Xuất cân kho',
        },
        {
          id: 8,
          name: 'Xuất kho thất thoát',
        },
        {
          id: 9,
          name: 'Xuất kho thanh lý',
        },
        {
          id: 10,
          name: 'Xuất kho đổi loại sản phẩm',
        },
      ],
      hoverPrice: false,
      stockSlipCode: '',
      showEditPriceModal: false,
      note: '',
      filteredOptionProvider: [],
      optionsProvider: [
        {
          data: [],
        },
      ],
      searchProvider: '',
      listProvider: [],
      filterdOptionsStore: [],
    };
  },
  methods: {
    ...getToastInstance(),
    convertPrice,
    fetchStore: async function () {
      this.options[0].data = [];
      ApiService.setHeader();
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        this.optionsStore = stores.map((element) => {
          return {
            code: element.id,
            name: element.name,
            shortName: element.shortName,
            checked: false,
          };
        });
        this.filterdOptionsStore = cloneDeep(this.optionsStore);
      });
    },
    fetchStock: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      let listStoreId = [];
      if (this.valueStore.length) {
        listStoreId = this.valueStore.map(({ code }) => code);
      }
      if (this.searchProvider.trim() === '') {
        this.selectedProvider = null;
      }
      const params = {
        page: this.page,
        limit: 10,
        searchDesciption: this.search,
        searchCode: this.searchId,
        fromDate: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 00:00:00',
            )
          : '',
        toDate: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 23:59:59',
            )
          : '',
        type: this.type,
        searchProduct: this.searchProduct,
        storeIds: listStoreId,
        companyId: this.selectedCompany,
        mode: this.selectedType,
        providerId: this.selectedProvider,
      };

      ApiService.query('stock-slips', { params })
        .then((response) => {
          this.stocks = [];
          const stockResponse = response.data.data.data;
          this.totalPages = response.data.data.totalPage;
          this.currentPage = response.data.data.currentPage;
          this.totalItems = response.data.data.totalRow;
          let result = [];
          stockResponse.forEach((item, index) => {
            let stock = {
              count:
                this.currentPage >= 2
                  ? index + 1 + this.currentPage * 10 - 10
                  : index + 1,
              id: item.id,
              code: item.code,
              sourceWarehouseId: item.sourceWarehouseId,
              providerId: item.providerId,
              providerName: item.providerName,
              description: item.description,
              totalAmount: item.totalAmount,
              type: item.type,
              createdAt: moment(String(item.createdAt)).format('DD/MM/YYYY'),
              createBy: item.createBy,
              relateStockSlipId: item.relateStockSlipId,
              billType: item.billType,
              idNhanh: item.idNhanh,
              countProduct: item.countProduct,
              countQuantity: item.countQuantity,
            };
            result.push(stock);
            this.stocks = [...new Set(result)];
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = true;
        });
    },
    editItem: function (item) {
      this.$router.push({
        name: 'update-export-stock',
        query: { id: item.id },
      });
    },
    onSelected(option) {
      this.selected = option.item.name;
      this.searchStock = option.item.name;
    },
    onInputChange(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa phiếu xuất kho!',
        text: 'Bạn có chắc muốn xóa phiếu xuất kho này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    deleteItem: async function (item) {
      ApiService.delete(`stock-slips/${item.id}`).then(({ data }) => {
        if (data.status === 1) {
          makeToastSuccess(data.message);
          this.fetchStock();
        } else {
          makeToastFaile(data.message);
        }
      });
    },
    linkToBill: function (item) {
      let nameRouter = '';
      if (item.billType === 1) {
        nameRouter = 'update-bill';
      } else if (item.billType === 2) {
        nameRouter = 'update-wholesale-invoice';
      }
      this.$router.push({
        name: nameRouter,
        query: { id: item.relateStockSlipId },
      });
    },
    fetchProduct() {
      this.optionsProduct[0].data = [];
      ApiService.setHeader();
      ApiService.get(
        `productSearch/withMerchant?searchProduct=${this.searchProduct}&storeId=&includeParent=true`,
      ).then(({ data }) => {
        const products = data.data;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [...this.optionsProduct[0].data];
        this.fetchStock();
      });
    },
    debounceInput: decounce(function () {
      this.fetchProduct();
    }, 500),
    onSelectedProduct(option) {
      this.searchProduct = option.item.productName;
    },
    onInputChangeProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInput();
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-export-stock',
      });
      this.fetchStock();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    onSelectStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name,
      );
      this.optionsStore[index].checked = true;
      this.filterdOptionsStore = cloneDeep(this.optionsStore);
    },

    onRemoveStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name,
      );
      this.optionsStore[index].checked = false;
      this.filterdOptionsStore = cloneDeep(this.optionsStore);
    },
    onInputStoreChange(textInput = '') {
      this.searchStore(textInput);
    },
    searchStore(textInput) {
      let options = cloneDeep(this.optionsStore);
      if (!textInput || !textInput.trim().length) {
        this.filterdOptionsStore = map(options, (obj) => {
          return assign(obj, find(this.filterdOptionsStore, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filterdOptionsStore, { id: obj.id }));
      });

      this.filterdOptionsStore = this.fitlerOptionsBy(
        options,
        textInput,
        'name',
        10,
      );
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    fetchCompany: async function () {
      this.listCompany = [];
      ApiService.setHeader();
      ApiService.get('/company').then((response) => {
        this.listCompany = response.data.data;
        this.listCompany.unshift({
          id: null,
          name: 'Tất cả',
        });
      });
    },
    onChangeCompany() {
      this.valueStore = [];
      this.searchStock = '';
      this.fetchStore();
    },
    getLinkDoc(item) {
      return `#/export-stocks/update-export-stock?id=${item.id}`;
    },
    reportClick: async function () {
      let listId = [];
      if (this.valueStore.length) {
        listId = this.valueStore.map(({ code }) => code);
      }
      if (this.searchProvider.trim() === '') this.selectedProvider = null;
      let searchDesciption = this.search;
      let searchCode = this.searchId;
      let searchFromDay = this.dpForm.startDate
        ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      let searchToDay = this.dpForm.endDate
        ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';
      let searchProduct = this.searchProduct;
      let companyId = this.selectedCompany;
      let typeSearch = this.selectedType;
      let baseUrl = BASE_URL;

      let url = `${baseUrl}stocks/export-excel-stock-slip?type=${
        this.type
      }&providerId=${
        this.selectedProvider
      }&searchProduct=${searchProduct}&searchDesciption=${searchDesciption}&searchCode=${searchCode}&fromDate=${searchFromDay}&toDate=${searchToDay}&companyId=${companyId}&typeSearch=${typeSearch}&${listId
        .map((n, index) => `listStoreId[${index}]=${n}`)
        .join('&')}`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
        onDownloadProgress: function (progressEvent) {
          console.log(progressEvent);
        },
      })
        .then((response) => {
          const fileName = fileDownload.getFileName(response);
          fileDownload.createFile(response.data, fileName);
        })
        .catch(async (error) => {
          const responseObj = JSON.parse(await error.response.data.text());
          return makeToastFaile(responseObj.message || 'Lỗi');
        });
    },
    handleShowNoteModel(item) {
      this.showEditPriceModal = !this.showEditPriceModal;
      this.stockSlipCode = item.code;
      this.note = item.description;
    },
    updateNote() {
      let data = {
        stockSlipCode: this.stockSlipCode,
        description: this.note,
      };
      ApiService.setHeader();
      ApiService.post('stocks/update-stockslip-note', data)
        .then(({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            this.showEditPriceModal = !this.showEditPriceModal;
            this.fetchStock();
          } else {
            makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    onSelectedProvider(option) {
      this.searchProvider = option.item.name;
      this.selectedProvider = option.item.id;
    },
    onInputChangeProvider(text) {
      if (!text) {
        text = '';
      }
      this.searchProvider = text;

      const filteredData = this.optionsProvider[0].data
        .filter((item) => {
          let itemName = xoa_dau(item.name);
          return itemName.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);

      this.filteredOptionProvider = [...filteredData];
    },
    fetchProvider() {
      ApiService.setHeader();
      ApiService.get(`providers/getAll`).then(({ data }) => {
        this.listProvider = data.data.providers;
        let noneProvider = {
          id: null,
          name: 'Tất cả NCC',
        };
        this.listProvider.unshift(noneProvider);
        this.listProvider.map((element) => {
          this.optionsProvider[0].data.push(element);
        });
        this.filteredOptionProvider = [...this.optionsProvider[0].data];
      });
    },
    printItem: function (item) {
      this.$router.push({
        name: 'print-export-stockslip',
        query: { id: item.id },
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Xuất kho', route: 'export-stocks' },
      { title: 'Danh sách phiếu xuất kho' },
    ]);
  },
  created() {
    this.fetchStock();
    this.fetchStore();
    this.fetchCompany();
    this.fetchProvider();
  },
  components: {
    KTCodePreview,
    VclTable,
    Autosuggest,
    BDropdownCustom,
  },
};
</script>

<style lang="scss">
.export-stocks {
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }

  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }

  .example {
    border: 1px solid #ddd;
    margin-bottom: 2em;
  }

  code,
  pre {
    margin: 1em 0;
    padding: 1em;
    border: 1px solid #bbb;
    display: block;
    background: #ddd;
    border-radius: 3px;
  }

  .settings {
    margin: 2em 0;
    border-top: 1px solid #bbb;
    background: #eee;
  }

  .form-group {
    margin-bottom: 1em;
  }

  .btn.btn-icon.btn-sm,
  .btn-group-sm > .btn.btn-icon {
    height: calc(1.35em + 1.1rem + 2px);
    width: auto;
  }

  .icon:hover {
    background-color: #90c6fc;
  }

  .sttClass {
    width: 20px;
  }

  .productCode:hover {
    text-decoration: underline;
  }
  .test {
    position: absolute;
    right: 1vw;
  }
  .checkbox-label {
    display: block;
  }
}
</style>
